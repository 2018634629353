import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ExternalLinkNewTab from "../components/external_link_new_tab"
import InternalLink from "../components/internal_link"


const FilmografiePage = () => (
  <Layout>
    <SEO title="Filmografie" />
    <h1>FILMOGRAFIE</h1>
    <p>Eine Auswahl der Film-Produktionen, an denen ich beteiligt war.</p>
    <br/>
    <h3>[FERNSEHEN]</h3> 
    <ul> 
    <li>    
            <b>BROWSER BALLETT - SATIRE IN SERIE</b> - Sitcom - <i>Das Erfolgsformat Browser Ballett meldet sich im neuen Gewand zurück, aber genauso böse wie früher. Nun als rasante Sitcom über den turbulenten Arbeitsalltag zweier Late-Night-Hosts. 
               </i> (Steinberger Silberstein GmbH/ NDR/ funk, 2021 Ausstrahlung bei One, ARD sowie ARD-Mediathek, beteiligt als Autor mehrerer Episoden: <ExternalLinkNewTab destination="https://www.ardmediathek.de/sendung/browser-ballett-satire-in-serie/staffel-1/Y3JpZDovL2Rhc2Vyc3RlLmRlL2Jyb3dzZXIgYmFsbGV0dA/1/"/>)
        </li>
        <li>    
            <b>SOKO STUTTGART</b> - Krimi Serie - <i>Martina Seifert und ihre Mitarbeiter lösen Morde in der Region Stuttgart. 
               </i> (Bavaria Film/ ZDF, 2021 beteiligt als Autor für Episode 17, Staffel 13: <ExternalLinkNewTab destination="https://www.zdf.de/serien/soko-stuttgart/geheime-verbindungen-100.html"/>)
        </li>  
        <li>    
            <b>BROWSER BALLETT SHOW</b> - Satireshow - <i>Das Browser Ballett, eines der erfolgreichsten Satire- und Comedyformate Deutschlands im Internet, jetzt auch im Fernsehen. 
               </i> (Steinberger Silberstein GmbH/ ARD, TV-Premiere 2020, beteiligt als Gag-Schreiber in Episode 1 und Episode 2: <ExternalLinkNewTab destination="https://www.ardmediathek.de/daserste/video/comedy-und-satire-im-ersten/browser-ballett/das-erste/Y3JpZDovL2Rhc2Vyc3RlLmRlL2NvbWVkeVNhdGlyZS81YTcyNzEwMS05OGFiLTRhNjctYmY1Zi05MzIxNzJhM2FjZjg"/>)
        </li>
        <li>
            <b>DER LEHRER</b> - Dramedyserie - <i>Mit ungewöhnlichen Methoden nimmt sich ein engagierter Lehrer seiner Schützlinge an und setzt sich für sie ein.</i> 
            (Sony Pictures/ RTL, 2019 beteiligt als Autor für Episode 4, Staffel 8: <ExternalLinkNewTab destination="https://www.tvnow.de/serien/der-lehrer-243"/>)
        </li> 
        <li>
            <b>SCHWESTER, SCHWESTER</b> - Sitcom - <i>Die Vollblutkrankenschwester Micki Busch hat einen Job als Stationsleiterin in einem angesehenen Krankenhaus erhalten – doch privat sieht es alles andere als rosig aus.</i> 
            (UFA Fiction/ RTL, 2020 beteiligt als Autor für Episode 5, Staffel 2: <ExternalLinkNewTab destination="https://www.tvnow.de/serien/schwester-schwester-18062"/>)
        </li> 
     </ul>
     <br/>
    <h3>[STREAMING]</h3> 
    <ul>   
    <li>    
            <b>JESSY.zip</b> - Horror-Serie - <i>In den Untiefen des Internets ist ein Ordner aufgetaucht. Voll mit mysteriösem Videomaterial einer verschollenen YouTuberin. Was ist Jessy passiert?
               </i> (Produziert 2019/20 an der Filmakademie BW, 2020 veröffentlicht über die ARD Mediathek/SWR, beteiligt als Autor und Co-Regisseur: <ExternalLinkNewTab destination="https://www.youtube.com/watch?v=2fCF8lgqvvQ"/>)
        </li>
        <li>
            <b>SINGLES’ DIARIES</b> - Romantic Comedyserie - <i>Fünf Singles in den Zwanzigern, die entweder die große Liebe suchen oder nur Spaß. Und obwohl ihre Geschichten völlig verschieden sind, haben sie doch mehr Gemeinsamkeiten, als es zunächst scheint.</i> (Produziert für joyn, 2018 beteiligt als Autor für Episode 8, Staffel 1: <ExternalLinkNewTab destination="https://www.joyn.de/serien/singles-diaries"/>)
        </li>
        <li>
            <b>AUREL ORIGINAL</b> - Satireshow - <i>Aurel Mertz greift vermeintlich alltägliche Themen aus seiner persönlichen Sicht auf – ohne erhobenen Zeigefinger, dafür aber mit viel Fingerspitzengefühl. </i> (2021 veröffentlicht über die ZDF Mediathek, beteiligt als Gag-Schreiber: <ExternalLinkNewTab destination="https://www.zdf.de/comedy/aurel-original"/>)
        </li>
        </ul>
        <br/>
    <h3>[WEB]</h3> 
        <ul>  
        <li>    
            <b>WULLA WUSSA</b> - Comedy-Webserie - <i>Über Menschen, die auf ein Musikfestival gehen, ohne zu merken, wie schrecklich sie alle sind.</i> (Veröffentlicht 2015 über Festival Guide, Preisträger des Mittelfränkischen Jugendfilmpreises 2015, beteiligt als Autor, Regisseur und Darsteller: <ExternalLinkNewTab destination="https://www.youtube.com/watch?v=fxVzq9M0SEE&list=PLwrBQXhvbiPanA7nJQuCyp03fiovwE0h7"/>)
        </li>
        <li>  
            <b>JANS FASHION BLOG</b> - satirische Vlog-Serie - <i>In diesem Blog erhaltet ihr ALLE Antworten auf Fragen, die ihr bezüglich Mode, Fashion, Trends & Styles niemals hattet.</i> (Veröffentlicht 2014 bis 2016, Preisträger des leider nur mittelfränkischen Webvideopreises 2016, beteiligt als Alles: <ExternalLinkNewTab destination="https://www.youtube.com/watch?v=HVV9vBYE87o"/>)
        </li>
        <li>  
          <b>WALULIS</b> - Satiresendung - <i>Das Gegengift zum YouTube-Wahnsinn! Wir analysieren Trends, Phänomene und Fernsehsendungen und verraten euch warum es so ist, wie es ist!</i> (Produziert für funk, seit 2017 beteiligt als Gag-Schreiber: <ExternalLinkNewTab destination="https://www.youtube.com/watch?v=FBLholf_kvY"/>)
        </li>
        <li>  
          <b>DER VEREIN</b> - satirischer Kurzfilm - <i>Eine Selbsthilfegruppe von Menschen, die an Poetry Slams erkrankt sind.</i> (Veröffentlicht 2015 über t°fau, beteiligt als Autor und Regisseur: <ExternalLinkNewTab destination="https://vimeo.com/116591998"/>)
        </li>
        <li>  
           <b>ICH, DAS VOLK</b> - satirischer Kurzfilm - <i>Porträt eines Wutbürgers, der loszieht, um von Westdeutschland nach Ostdeutschland auszuwandern.</i> (Veröffentlicht 2016, Preisträger des Publikumspreises beim Franz Mehlhose Kurzfilmabend Erfurt, beteiligt als Autor und Darsteller: <ExternalLinkNewTab destination="https://www.youtube.com/watch?v=2B9LC9ooSrA"/>)
        </li>
        <li>  
           <b>ANARCHIE REVOLUTION</b> - Kurzfilm - <i>Eine dörfliche Punkrockband, die gerne die Ungerechtigkeiten auf der Welt besiegen würde, wenn sie nicht so faul wäre.</i>  (Veröffentlicht 2011, Preisträger des Bayerischen Jugendfilmpreis 2012 für beste Regie, beteiligt als Autor, Regisseur und Darsteller: <ExternalLinkNewTab destination="https://www.youtube.com/watch?v=VT6WstGlUI4"/>)
        </li>
        <li>  
           <b>VIER SPINNER UND EIN IRRER</b> - Serienpilot - <i>Ein blauäugiger Romantiker muss sich im chaotischen Klinikalltag einer Psychiatrie als Pfleger beweisen, um die Liebe seines Lebens zurückzugewinnen.</i> (Produziert 2018/19 an der Filmakademie BW, beteiligt als Autor und Creator: <ExternalLinkNewTab destination="https://vimeo.com/343633839"/>, PW auf Anfrage)
        </li>
        <li>  
          <b>IN TOBIS KÜCHE</b> - Comedy-Webserie - <i>Drei Freunde starten eine Online- Kochshow, bis sie merken, dass es mehr Klicks gibt, wenn sie waghalsige  Stunts vollführen.</i> (Veröffentlicht 2014 über myspass.de, Entwickelt für Ralf Husmann und Brainpool, beteiligt als Autor, Regisseur und Darsteller: <ExternalLinkNewTab destination="https://www.myspass.de/shows/webshows/in-tobis-kueche/"/>)
        </li>
     </ul>
        <br/>
    <h3>[IN ENTWICKLUNG]</h3> 
        <ul>  
        <li>  
          <b>FADBERG</b> - Comedy Serie - (zusammen mit Frederik Felkner, beteiligt als Autor und Creator)
        </li>
        <li>  
          <b>ALL INCLUSIVE</b> - Comedyserie - (zusammen mit Carly Schrader und Paul Prenissl, beteiligt als Autor und Creator)
        </li>
        </ul>
     <br/>
    <InternalLink destination="/" label="ZURÜCK"/>
  </Layout>
)

export default FilmografiePage
